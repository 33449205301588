<template>
  <Modal ref="reservationModal" :mask="mask">
    <template #default="{ hide }">
      <ReservationForm
        ref="reservationForm"
        :draggable="draggable"
        :has-title="true"
        :is-submitting="isSubmitting"
        :value="{}"
        @submit="handleSubmit"
        @cancel="hide"
      />
    </template>
    <template #handler="{ show }">
      <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
        {{ $t('Add New Reservation') }}
      </a>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ReservationForm from '@/views/reservations/components/ReservationForm';

export default {
  name: 'ReservationAddModal',
  components: {
    Modal,
    ReservationForm,
  },
  props: {
    mask: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      isSubmitting: false,
      reservation: undefined,
    };
  },
  methods: {
    handleSubmit(data) {
      this.isSubmitting = true;
      this.$store
        .dispatch('reservations/add', data)
        .then((resp) => {
          this.reservation = resp.data;
          this.$refs.reservationForm.resetFields();
          this.isSubmitting = false;
          this.$emit('close');
          this.$refs.reservationModal.hide();
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Add New Reservation": "Add New Reservation"
  },
  "ja": {
    "Add New Reservation": "新規予約を追加"
  }
}
</i18n>
