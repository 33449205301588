<template>
  <Modal :mask="mask">
    <template #default="{ hide }">
      <BlockDateForm
        :draggable="draggable"
        :value="{}"
        @close="
          () => {
            hide();
            fetchData();
          }
        "
        @cancel="hide"
      />
    </template>
    <template #handler="{ show }">
      <a class="ant-btn ant-btn-lg button-secondary-outline" @click.prevent="show">
        {{ $t('Block Dates') }}
      </a>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import BlockDateForm from '@/views/calendar/components/BlockDateForm';

export default {
  name: 'BlockDateAddModal',
  components: {
    Modal,
    BlockDateForm,
  },
  props: {
    mask: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
  },
  emits: ['close'],
  methods: {
    fetchData() {
      this.$emit('close');
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Block Dates": "Block Dates"
  },
  "ja": {
    "Block Dates": "ブロック日"
  }
}
</i18n>
