<template>
  <a-form ref="form" :model="form" :rules="modifiedRules" class="columns is-mini-gap is-multiline">
    <div v-if="hasTitle" class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        <IconMove v-if="draggable" width="18" height="18" />
        {{ title }}
      </h2>
    </div>
    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" @change="handlePropertyChange" />
    </a-form-item>
    <!-- <a-form-item
      v-if="isAnshin"
      class="column is-12"
      :label="$t('Temporary Key Code')"
      name="temporaryKeyCode"
    >
      <a-input
        v-model:value.trim="form.temporaryKeyCode"
        :size="size"
        type="text"
      />
    </a-form-item>
    <a-form-item
      v-if="isAnshin"
      class="column is-12"
      :label="$t('Smartlock Key Code')"
      name="smartlockKeyCode"
    >
      <a-input
        v-model:value.trim="form.smartlockKeyCode"
        :size="size"
        type="text"
      />
    </a-form-item> -->
    <a-form-item class="column is-12" :label="$t('Sales Office Company')" name="salesOfficeCompany">
      <a-input v-model:value="form.salesOfficeCompany" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Travel Agency Booking Number')"
      name="agencyBookingNumber"
    >
      <a-input v-model:value.trim="form.agencyBookingNumber" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check in - check out')" name="checkInOutDate">
      <a-range-picker
        v-model:value="form.checkInOutDate"
        style="width: 100%"
        :disabled-date="disabledDate"
        :size="size"
        :format="dateFormat"
      >
        <a-input :value="rangeString" :placeholder="$t('Date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check-in Time')" name="checkInTime">
      <a-select v-model:value="form.checkInTime" :placeholder="$t('Please select')" :size="size">
        <a-select-option v-for="o in timeOptions" :key="o.value" :value="o.value">
          {{ o.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Total Accommodation Charge')"
      name="totalAccommodationCharge"
    >
      <a-input-number
        v-model:value.trim="form.totalAccommodationCharge"
        :size="size"
        type="text"
        style="width: 100%"
      />
    </a-form-item>
    <div class="column is-12">
      <div class="form-title">
        {{ $t('Room Information') }}
      </div>
    </div>
    <a-form-item
      v-if="useRoomType"
      class="column is-12"
      :label="$t('Room Type')"
      name="roomTypeId"
    >
      <InputRoomType
        v-model:value="form.roomTypeId"
        :property-id="form.propertyId"
        @change="handleRoomTypeChange"
      />
    </a-form-item>
    <a-form-item
      v-if="useRoomType"
      class="column is-12"
      :label="$t('Room Number')"
      name="roomNumber"
    >
      <InputRoomNumber
        v-model:value="form.roomNumber"
        :property-id="form.propertyId"
        :room-type-id="form.roomTypeId"
        allow-clear
      />
    </a-form-item>
    <a-form-item
      v-if="!useRoomType"
      class="column is-12"
      :label="$t('Room Name')"
      name="roomCode"
    >
      <InputRoom
        v-model:value="form.roomCode"
        :property-id="form.propertyId"
        :room-type-id="form.roomTypeId"
        :use-room-type="useRoomType"
        return-value="code"
        @change="handleRoomChange"
      />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Male Count')" name="paxMaleCount">
      <a-input v-model:value.trim="form.paxMaleCount" :size="size" type="number" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Female Count')" name="paxFemaleCount">
      <a-input v-model:value.trim="form.paxFemaleCount" :size="size" type="number" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Child A70 Count')" name="childA70Count">
      <a-input v-model:value.trim="form.childA70Count" :size="size" type="number" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Child B50 Count')" name="childB50Count">
      <a-input v-model:value.trim="form.childB50Count" :size="size" type="number" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Child C30 Count')" name="childC30Count">
      <a-input v-model:value.trim="form.childC30Count" :size="size" type="number" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Child DNone Count')" name="childDNoneCount">
      <a-input v-model:value.trim="form.childDNoneCount" :size="size" type="number" />
    </a-form-item>
    <div class="column is-12">
      <div class="form-title">
        {{ $t('Guest Information') }}
      </div>
    </div>
    <a-form-item class="column is-12" :label="$t('Guest Name')" name="guestName">
      <a-input v-model:value="form.guestName" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Guest Email')" name="guestEmail">
      <a-input v-model:value.trim="form.guestEmail" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Country Code')" name="countryCode">
      <InputCountry v-model:value="form.countryCode" type="phone" @change="handleCountryChange" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Guest Phone')" name="guestPhone">
      <a-input v-model:value.trim="form.guestPhone" :size="size" type="text" />
    </a-form-item>
    <div class="column is-12 m-t-4x" :class="{ 'has-text-right': hasTitle }">
      <a-button
        v-if="hasTitle"
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Reservation": "Edit Reservation",
    "Add Reservation": "Add Reservation",
    "Cancel": "Cancel",
    "Sales Office Company": "Sales Office Company",
    "Travel Agency Booking Number": "Travel Agency Booking Number",
    "Check in - check out": "Check in - check out",
    "Room Information": "Room Information",
    "Total Pax": "Total Pax",
    "Male Count": "Male Count",
    "Female Count": "Female Count",
    "Child A70 Count": "Child A70 Count",
    "Child B50 Count": "Child B50 Count",
    "Child C30 Count": "Child C30 Count",
    "Child DNone Count": "Child DNone Count",
    "Guest Information": "Guest Information",
    "Guest Email": "Guest Email",
    "Country Code": "Country Code",
    "Guest Phone": "Guest Phone",
    "Smartlock Key Code": "Smartlock Key Code",
    "Temporary Key Code": "Temporary Key Code",
    "Check-in Time": "Check in Time",
    "Total Accommodation Charge": "Total Accommodation Charge",
    "error": {
      "property-required": "Property is required",
      "reservationNumber-required": "Travel agency booking number is required",
      "room-required": "Room is required",
      "roomTypeId-required": "Room type is required",
      "roomNumber-required": "Room number is required",
      "date-required": "Date is required",
      "guest-name-required": "Guest name is required",
      "guest-email-required": "Guest email is required",
      "guest-email-invalid": "Guest email is invalid",
      "country-code-required": "Country code is required",
      "guest-phone-required": "Guest phone is required",
      "guest-phone-min": "Minimum 6 digits phone number required",
      "guest-phone-type": "Please input number only",
      "date-must-be-different": "Check in - check out date must be +1 day or more",
      "total-accommodation-charge-type": "Total Accommodation charge must be number",
      "invalid-reservation-number": "Reservation number is invalid",
      "reservationNumber-required": "Reservation number is required",
    }
  },
  "ja": {
    "Edit Reservation": "予約を編集",
    "Add Reservation": "新規予約を追加",
    "Cancel": "キャンセル",
    "Sales Office Company": "旅行会社名",
    "Travel Agency Booking Number": "旅行会社予約(管理)番号",
    "Check in - check out": "チェックイン時刻 - チェックアウト時刻",
    "Room Information": "部屋情報",
    "Total Pax": "利用人数",
    "Male Count": "大人人数(男性)",
    "Female Count": "大人人数(女性)",
    "Child A70 Count": "子供A人数",
    "Child B50 Count": "子供B人数",
    "Child C30 Count": "子供C人数",
    "Child DNone Count": "子供D人数",
    "Guest Information": "ゲスト情報",
    "Guest Email": "お客様Email",
    "Country Code": "国コード",
    "Guest Phone": "お客様電話番号",
    "Smartlock Key Code": "スマートロックキーコード",
    "Temporary Key Code": "一時的なキーコード",
    "Check-in Time": "チェックイン時間",
    "Total Accommodation Charge": "宿泊費合計",
    "error": {
      "property-required": "プロパティは必須です",
      "reservationNumber-required": "旅行会社予約(管理)番号は必須です",
      "room-required": "部屋は必須です",
      "roomTypeId-required": "部屋タイプは必須です",
      "roomNumber-required": "Room number is required",
      "date-required": "日付は必須です",
      "guest-name-required": "ゲスト名は必須です",
      "guest-email-required": "ゲストのメールアドレスは必須です",
      "guest-email-invalid": "ゲストのメールアドレスは無効です",
      "country-code-required": "国コードは必須です",
      "guest-phone-required": "お客様電話番号は必須です",
      "guest-phone-min": "6桁以上の電話番号が必要です",
      "guest-phone-type": "番号のみを入力してください",
      "date-must-be-different": "1泊以上の日付を選択して下さい",
      "total-accommodation-charge-type": "宿泊費の合計は数字でなければなりません",
      "invalid-reservation-number": "旅行会社予約(管理)番号は無効です",
      "reservationNumber-required": "旅行会社予約(管理)番号は必須です",
    }
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { CalendarFilled } from '@ant-design/icons-vue';
import { TIME_OPTIONS } from '@/config/dates';
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import InputCountry from '@/components/InputCountry';
import IconMove from '@/components/icons/IconMove';
import InputRoomType from '@/components/InputRoomType';
import InputRoomNumber from '@/components/InputRoomNumber';

export default {
  name: 'ReservationForm',
  components: {
    CalendarFilled,
    InputProperty,
    InputRoom,
    InputCountry,
    IconMove,
    InputRoomType,
    InputRoomNumber,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
    hasTitle: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      useRoomType: false,
      isAnshin: false,
      dateFormat: 'YYYY-MM-DD',
      form: {
        propertyId: undefined,
        propertyName: undefined,
        smartlockKeyCode: undefined,
        roomCode: undefined,
        roomTypeId: undefined,
        roomNumber: undefined,
        agencyBookingNumber: undefined,
        checkInOutDate: [],
        checkInTime: undefined,
        totalAccommodationCharge: undefined,
        salesOfficeCompany: undefined,
        perRoomPaxCount: undefined,
        paxMaleCount: undefined,
        paxFemaleCount: undefined,
        childA70Count: undefined,
        childB50Count: undefined,
        childC30Count: undefined,
        guestName: undefined,
        guestPhone: undefined,
        countryCode: undefined,
        guestEmail: undefined,
        type: 'manual',
        temporaryKeyCode: undefined,
      },
      timeOptions: TIME_OPTIONS,
      size: 'large',
      rules: {
        agencyBookingNumber: [
          {
            trigger: ['change', 'blur'],
            validator: (rule, value) => new Promise((resolve, reject) => {
              if (!/^[a-zA-Z0-9-]+$/.test(value)) {
                return reject(this.$t('error.invalid-reservation-number'));
              }

              return resolve();
            }),
          },
        ],
        propertyId: [
          {
            required: true,
            message: this.$t('error.property-required'),
            trigger: ['change', 'blur'],
          },
        ],
        roomCode: [
          {
            required: true,
            message: this.$t('error.room-required'),
            trigger: ['change', 'blur'],
          },
        ],
        checkInOutDate: [
          {
            required: true,
            type: 'array',
            message: this.$t('error.date-required'),
            trigger: ['change', 'blur'],
          },
          {
            trigger: ['change', 'blur'],
            validator: (rule, value) => new Promise((resolve, reject) => {
              const checkin = dayjs(value[0]._d).format('YYYY-MM-DD');
              const checkout = dayjs(value[1]._d).format('YYYY-MM-DD');

              if (dayjs(checkout).diff(checkin, 'day') === 0) {
                reject(this.$t('error.date-must-be-different'));
              }
              if (dayjs().diff(checkin, 'days') > 0) {
                reject(this.$t('error.date-must-be-different'));
              }

              resolve();
            }),
          },
        ],
        guestName: [
          {
            required: true,
            message: this.$t('error.guest-name-required'),
            trigger: ['change', 'blur'],
          },
        ],
        guestEmail: [
          {
            required: true,
            message: this.$t('error.guest-email-required'),
            trigger: ['change', 'blur'],
          },
          {
            type: 'email',
            message: this.$t('error.guest-email-invalid'),
            trigger: ['change', 'blur'],
          },
        ],
        guestPhone: [
          {
            min: 6,
            message: this.$t('error.guest-phone-min'),
            trigger: ['change', 'blur'],
          },
          {
            pattern: new RegExp('^[0-9]*$'),
            message: this.$t('error.guest-phone-type'),
            trigger: ['change', 'blur'],
          },
          {
            required: true,
            message: this.$t('error.guest-phone-required'),
            trigger: ['change', 'blur'],
          },
        ],
        countryCode: [
          {
            required: true,
            message: this.$t('error.country-code-required'),
            trigger: ['change', 'blur'],
          },
        ],
        totalAccommodationCharge: [
          {
            type: 'number',
            message: this.$t('error.total-accommodation-charge-type'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    rangeString() {
      return this.form.checkInOutDate.length
        ? `${this.$filters.date(this.form.checkInOutDate[0], {
          format: this.dateFormat,
        })} ~ ${this.$filters.date(this.form.checkInOutDate[1], { format: this.dateFormat })}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      return this.form.checkInOutDate.length
        ? `${this.$filters.date(this.form.checkInOutDate[0], {
          format: dateFormat,
        })},${this.$filters.date(this.form.checkInOutDate[1], { format: dateFormat })}`
        : undefined;
    },
    title() {
      return this.hasTitle && this.value && this.value.id
        ? this.$t('Edit Reservation')
        : this.$t('Add Reservation');
    },
    modifiedRules() {
      const rules = { ...this.rules };
      if (this.useRoomType) {
        rules.roomTypeId = [
          {
            required: true,
            type: 'number',
            message: this.$t('error.roomTypeId-required'),
            trigger: ['change', 'blur'],
          },
        ];
      }

      return rules;
    },
  },
  created() {
    this.form.propertyId = this.$route.query.propertyId || undefined;
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        const {
          propertyId,
          roomCode,
          roomNumber,
          type,
        } = this.form;

        const payload = {
          type,
          propertyId,
          reservationNumber: this.form.agencyBookingNumber,
          ...this.getReservationDates(),
          room: {
            propertyId,
            roomCode,
            roomNumber,
            info: this.getRoomInfo(),
            guests: this.getRoomGuests(),
          },
          details: this.getDetails(),
        };
        this.$emit('submit', { ...payload });
      } catch (e) {
        console.log(e);
      }
    },
    getReservationDates() {
      const range = this.form.checkInOutDate;
      const dates = {
        checkin: undefined,
        checkout: undefined,
      };

      if (range && range.length === 2) {
        const [checkin, checkout] = range;

        dates.checkin = this.$filters.date(checkin, { format: this.dateFormat });
        dates.checkout = this.$filters.date(checkout, { format: this.dateFormat });
      }

      return dates;
    },
    getRoomInfo() {
      const info = {
        paxMaleCount: this.form.paxMaleCount ? parseInt(this.form.paxMaleCount, 10) : 0,
        paxFemaleCount: this.form.paxFemaleCount ? parseInt(this.form.paxFemaleCount, 10) : 0,
        childA70Count: this.form.childA70Count ? parseInt(this.form.childA70Count, 10) : 0,
        childB50Count: this.form.childB50Count ? parseInt(this.form.childB50Count, 10) : 0,
        childC30Count: this.form.childC30Count ? parseInt(this.form.childC30Count, 10) : 0,
        childDNoneCount: this.form.childDNoneCount ? parseInt(this.form.childDNoneCount, 10) : 0,
      };
      const perRoomPaxCount = Object.values(info).reduce((a, b) => a + b);

      return { ...info, perRoomPaxCount };
    },
    getRoomGuests() {
      return [
        {
          surname: this.form.guestName,
          phone: `${this.form.countryCode}${this.form.guestPhone}`,
          email: this.form.guestEmail,
        },
      ];
    },
    getDetails() {
      const { checkin, checkout } = this.getReservationDates();

      return {
        transactionType: {
          dataClassification: 'NewBookReport',
        },
        smartlockKeyCode: this.form.smartlockKeyCode,
        temporaryKeyCode: this.form.temporaryKeyCode,
        basicInfo: {
          nights: dayjs(checkout).diff(dayjs(checkin), 'd'),
          agencyBookingNumber: this.form.agencyBookingNumber,
          checkinTime: this.form.checkInTime,
        },
        basicRateInfo: {
          totalAccommodationCharge: this.form.totalAccommodationCharge,
        },
        salesOfficeInfo: { companyName: this.form.salesOfficeCompany },
        accommodationInfo: { name: this.form.propertyName },
      };
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    handlePropertyChange(val) {
      this.form.propertyId = val.newPropertyCode;
      this.form.propertyName = val.name;
      if (val.anshinPropertyId) {
        this.isAnshin = true;
      } else {
        this.isAnshin = false;
      }

      if (val.useRoomType) {
        this.useRoomType = true;
      } else {
        this.useRoomType = false;
      }

      // reset rooms
      this.form.roomCode = undefined;
      this.form.roomTypeId = this.$route.query.roomTypeId || undefined;
      this.form.roomNumber = undefined;
    },
    handleCountryChange(val) {
      this.form.countryCode = val;
    },
    handleRoomTypeChange(_id, data) {
      this.form.roomCode = data.neppanRoomType;
    },
    handleRoomChange(val) {
      this.form.roomCode = val.toString();
    },
    disabledDate(current) {
      // Can not select days before yesterday and today
      return current && current < dayjs().subtract(1, 'day').endOf('day');
    },
  },
};
</script>
