<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        <IconMove v-if="draggable" width="18" height="18" />
        {{ title }}
      </h2>
    </div>
    <a-form-item class="column is-12" :label="$t('Property Name')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" :partner-id="form.partnerId" @change="handleProperty" />
    </a-form-item>
    <a-form-item
      v-if="property && property.useRoomType"
      class="column is-12"
      :label="$t('Room Type')"
      name="roomTypeId"
    >
      <InputRoomType
        v-model:value="form.roomTypeId"
        :property-id="property.newPropertyCode"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="roomId">
      <InputRoom
        v-model:value="form.roomId"
        :property-id="form.propertyId"
        :room-type-id="form.roomTypeId"
        :use-room-type="property && property.useRoomType"
        :allow-all="true"
        @change="handleInputRoom"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Date range')" name="range">
      <a-range-picker
        v-model:value="form.range"
        :size="size"
        :format="dateFormat"
        style="width: 100%"
        @change="debounceQuery"
      >
        <a-input :value="rangeString" :placeholder="$t(`Block dates range`)" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Reason')" name="reason">
      <a-input v-model:value="form.reason" :size="size" placeholder="Basic usage" />
    </a-form-item>
    <div class="column is-12 m-t-4x" :class="{ 'has-text-right': value }">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit Block Dates": "Edit Block Dates",
    "Add Block Dates": "Add Block Dates",
    "Property Name": "Property Name",
    "Room Name": "Room Name",
    "Date range": "Date range",
    "Block dates range": "Block dates range",
    "Reason": "Reason",
    "Cancel": "Cancel",
    "Save": "Save",
    "property-required": "Property is required",
    "room-type-required": "Room type is required",
    "room-required": "Room is required",
    "range-required": "Date range is required",
    "reason-required": "Reason is required"
  },
  "ja": {
    "Edit Block Dates": "ブロック日を編集",
    "Add Block Dates": "ブロック日を追加",
    "Property Name": "物件正式名称",
    "Room Name": "部屋番号",
    "Date range": "日付範囲",
    "Block dates range": "ブロック日付範囲",
    "Reason": "理由",
    "Cancel": "取り消し",
    "Save": "保存",
    "property-required": "物件は必須です",
    "room-type-required": "部屋タイプは必須です",
    "room-required": "部屋は必須です",
    "range-required": "日付範囲は必須です",
    "reason-required": "理由は必須です"
  }
}
</i18n>

<script>
import { CalendarFilled } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import InputProperty from '@/components/InputProperty';
import InputRoomType from '@/components/InputRoomType';
import InputRoom from '@/components/InputRoom';
import IconMove from '@/components/icons/IconMove';

export default {
  name: 'RoomAssignmentForm',
  components: {
    IconMove,
    InputRoom,
    InputProperty,
    InputRoomType,
    CalendarFilled,
  },
  props: {
    draggable: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel'],
  data() {
    return {
      isSubmitting: false,
      dateFormat: 'YYYY-MM-DD',
      property: undefined,
      form: {
        propertyId: undefined,
        roomId: undefined,
        reason: undefined,
        range: [],
        rooms: [],
      },
      size: 'large',
    };
  },
  computed: {
    title() {
      return this.value && this.value.id ? this.$t('Edit Block Dates') : this.$t('Add Block Dates');
    },
    rangeString() {
      const { range } = this.form;

      if (!range || range.length === 0) {
        return '';
      }

      const [from, to] = range;
      const { date } = this.$filters;
      const opts = { format: this.dateFormat };

      return `${date(from, opts)} ~ ${date(to, opts)}`;
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.form.range.length) {
        range.dateFrom = this.$filters.date(this.form.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.form.range[1], { format: dateFormat });
      }

      return range;
    },
    rules() {
      const rules = {
        propertyId: [
          {
            required: true,
            message: this.$t('property-required'),
            trigger: ['change', 'blur'],
          },
        ],
        roomId: [
          {
            type: 'number',
            required: true,
            message: this.$t('room-required'),
            trigger: ['change', 'blur'],
          },
        ],
        range: [
          {
            type: 'array',
            required: true,
            message: this.$t('range-required'),
            trigger: ['change', 'blur'],
          },
        ],
        reason: [
          {
            type: 'string',
            required: true,
            message: this.$t('reason-required'),
            trigger: ['change', 'blur'],
          },
        ],
      };

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
              if (['partnerId', 'roomId'].includes(k)) {
                this.form[k] = parseInt(nv[k], 10);
              }
            }
          });
        }
      },
    },
  },
  created() {
    if (this.$store.getters.roles.includes('HC')) {
      this.form.partnerId = this.$store.getters.userPartner.id;
    }

    this.form.propertyId = this.$route.query.propertyId || undefined;
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        const dates = [];
        const dateDifference = dayjs(this.formattedRange.dateTo).diff(
          this.formattedRange.dateFrom,
          'days',
        );
        for (let i = 0; i <= dateDifference; i += 1) {
          dates.push(dayjs(this.formattedRange.dateFrom).add(i, 'days').format('YYYY-MM-DD'));
        }
        const data = {
          ...this.form,
          range: undefined,
          dates,
        };

        if (this.form.roomId > 0) {
          await this.$store.dispatch('calendar/addBlockedDates', { roomId: this.form.roomId, data });
        } else {
          await this.$store.dispatch('calendar/addBlockedDatesToRooms', { data });
        }

        this.isSubmitting = false;
        this.$emit('close');
      } catch (e) {
        this.isSubmitting = false;
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    handleInputRoom(_id, roomIds) {
      this.form.rooms = roomIds;
    },
    handleProperty(property) {
      this.property = property;

      this.form.rooms = [];
      this.form.roomId = '';
    },
  },
};
</script>
