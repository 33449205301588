import checkRole from '@/utils/role';
import { ROLES, INTERNAL, REPORTS } from '@/config/roles';

export default {
  computed: {
    isInternal() {
      return checkRole(INTERNAL);
    },
  },
  created() {
    this.ROLES = {
      ...ROLES,
      INTERNAL,
      REPORTS,
    };
  },
};
