<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('Select room number')"
    style="width: 100%"
    dropdown-class-name="room-max-content-width"
    :default-active-first-option="false"
    :not-found-content="null"
    :size="size"
    :filter-option="filterOption"
    :allow-clear="allowClear"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d[returnValue]" :title="d.number" :filter="d.number">
      {{ `${d.building ? d.building+' - ' : ''}${d.number}` }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select room number": "Select room number"
  },
  "ja": {
    "Select room number": "Select room number"
  }
}
</i18n>

<script>
export default {
  name: 'InputRoomNumber',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    returnValue: {
      type: String,
      default: 'number',
    },
    propertyId: {
      type: String,
      default: null,
    },
    roomTypeId: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:value', 'changeRoomNumber'],
  data() {
    return {
      id: undefined,
      data: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (this.returnValue === 'id') {
          this.id = nv ? parseInt(nv, 10) : undefined;
        } else {
          this.id = nv || undefined;
        }
      },
    },
    propertyId: {
      immediate: true,
      handler() {
        this.data = [];
        this.handleSearch().then(() => {
          const exist = this.data.find((room) => room[this.returnValue] === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
    roomTypeId: {
      immediate: true,
      handler() {
        this.data = [];
        this.handleSearch().then(() => {
          const exist = this.data.find((room) => room[this.returnValue] === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch() {
      const params = {
        propertyId: this.propertyId,
        roomTypeId: this.roomTypeId,
        roomNumbersOnly: true,
        infinite: true,
      };

      return this.$store.dispatch('rooms/names', params).then((res) => {
        this.data = res.list;
        return Promise.resolve();
      });
    },
    filterOption(input, option) {
      return option.filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.id);
      this.$emit('update:value', this.id);
      this.getRoomNumber(value);
    },
    getRoomNumber(value) {
      const room = this.data.find((el) => el.code === value);
      this.$emit('changeRoomNumber', room?.number);
    },
  },
};
</script>

<style>
.room-max-content-width {
  min-width: 250px !important;
}
@media screen and (max-width: 380px) {
  .room-max-content-width {
    min-width: auto !important;
  }
}
</style>
